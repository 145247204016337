import React from "react"
import { Container, Row } from "react-bootstrap"

const TermsOfServiceRo = (): JSX.Element => (
  <Row className="bg-white tos-page pt-3 pb-5" noGutters>
    <Container className="py-5">
      <h3 className="mb-3">
        <strong>1. Termeni</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          Accesând site-ul web{" "}
          <a href="http://radar.mediacritica.md">
            radar.mediacritica.md
          </a>{" "}
          sunteți de acord să fiți obligați de acești termeni de serviciu, de
          toate legile și reglementările aplicabile și sunteți de acord că
          sunteți responsabil pentru respectarea oricăror legi locale
          aplicabile. Dacă nu sunteți de acord cu niciunul dintre acești
          termeni, vi se interzice utilizarea sau accesarea acestui site.
          Materialele conținute de acest site web sunt protejate de legea
          aplicabilă a drepturilor de autor.
        </p>
      </div>
      <h3 className="mb-3">
        <strong>2. Licența</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          a) Se acordă permisiunea de a descărca temporar o copie a materialelor
          (informații sau software) pe site-ul web al Media RADAR, gestionat de
          Centrul pentru Jurnalism Independent (CJI), numai pentru vizualizare
          tranzitorie personală, non-comercială. Aceasta este acordarea unei
          licențe, nu a unui transfer de titlu și, în conformitate cu această
          licență, nu puteți:
        </p>
        <ul>
          <li>modifica sau copia materialele;</li>
          <li>
            utiliza materialele în orice scop comercial sau pentru orice afișare
            publică (comercială sau necomercială);
          </li>
          <li>
            încerca de a compila sau inversa orice software conținut pe site-ul
            Media RADAR, gestionat de CJI;
          </li>
          <li>
            elimina din materialele de utilizare orice alte drepturi de autor
            sau alte notificări proprii; sau transfera materialele către o altă
            persoană sau „oglindi” materialele de pe orice alt server.
          </li>
        </ul>
        <p>
          b) Această licență se încheie automat dacă încălcați oricare dintre
          aceste restricții și pot fi încetate de CJI în orice moment. După
          încetarea vizualizării acestor materiale sau la încetarea acestei
          licențe, trebuie să distrugeți orice materiale descărcate în posesia
          dvs., fie în format electronic sau tipărit.
        </p>
      </div>
      <h3 className="mb-3">
        <strong>3. Disclaimer</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          a) Materialele de pe site-ul Media RADAR, gestionat de CJI, sunt
          furnizate în mod „așa cum este”. CJI nu oferă nicio garanție,
          exprimată sau implicită și, prin prezenta, renunță și neagă toate
          celelalte garanții, inclusiv, fără limitare, garanții implicite sau
          condiții de comerciabilitate, aptitudini pentru un anumit scop sau
          nerespectarea proprietății intelectuale sau alte încălcări ale
          drepturilor.
        </p>
        <p>
          b) În plus, CJI nu garantează și nu face nicio reprezentare cu privire
          la acuratețea, rezultatele probabile sau fiabilitatea utilizării
          materialelor de pe site-ul său web sau în legătură cu alte materiale
          sau pe site-uri legate de acest site.
        </p>
      </div>
      <h3 className="mb-3">
        <strong>4. Limitări</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          În nici un caz CJI sau furnizorii săi nu vor fi responsabili pentru
          daune (inclusiv, fără limitare, daune pentru pierderea de date) care
          rezultă din utilizarea sau incapacitatea de a utiliza materialele de
          pe site-ul Media RADAR, chiar dacă CJI sau un reprezentant autorizat
          CJI a fost notificat oral sau în scris despre posibilitatea unei
          astfel de daune. Deoarece unele jurisdicții nu permit limitări ale
          garanțiilor implicite sau limitări ale răspunderii pentru daune
          cauzale sau incidentale, este posibil ca aceste limități să nu se
          aplice pentru dvs.
        </p>
      </div>
      <h3 className="mb-3">
        <strong>5. Precizia materialelor</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          Materialele care apar pe site-ul Media RADAR, gestionat de CJI, ar
          putea include erori tehnice, tipografice sau fotografice. CJI nu
          garantează ca materialele de pe site-ul său web este exact, complet
          sau actual. CJI poate efectua modificări ale materialelor conținute pe
          site-ul său web în orice moment, fără notificare prealabilă. Cu toate
          acestea, CJI nu se angajează să actualizeze materialele.
        </p>
      </div>
      <h3 className="mb-3">
        <strong>6. Link-uri</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          CJI nu a revizuit toate site-urile legate de site-ul său web și nu
          este responsabil pentru conținutul unui astfel de site legat.
          Includerea oricărui link nu implică aprobarea CJI a site-ului.
          Utilizarea oricărui astfel de site web legat este pe riscul
          utilizatorului.
        </p>
      </div>
      <h3 className="mb-3">
        <strong>7. Modificări</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          CJI poate revizui acești termeni și condiții pentru site-ul său web în
          orice moment, fără notificare prealabilă. Prin utilizarea acestui site
          web, sunteți de acord să fiți legat de versiunea actuală a acestor
          termeni și condiții.
        </p>
      </div>
      <h3 className="mb-3">
        <strong>8. Legea guvernantă</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          Acești termeni și condiții sunt reglementați și interpretați în
          conformitate cu legile Republicii Moldova și vă supuneți irevocabil
          jurisdicției exclusive a instanțelor din acest stat.
        </p>
      </div>
    </Container>
  </Row>
)

export default TermsOfServiceRo
