import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { useIntl } from "gatsby-plugin-intl"
import TermsOfServiceRo from "../components/tos.ro"
import TermsOfServiceRu from "../components/tos.ru"

const TermsOfServicePage = (): JSX.Element => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "tos.subtitle" })} />
      <Header subtitle={intl.formatMessage({ id: "tos.subtitle" })} />
      {intl.locale === "ru" ? <TermsOfServiceRu /> : <TermsOfServiceRo />}
    </Layout>
  )
}

export default TermsOfServicePage
