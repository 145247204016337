import React from "react"
import { Container, Row } from "react-bootstrap"

const TermsOfServiceRu = (): JSX.Element => (
  <Row className="bg-white tos-page pt-3 pb-5" noGutters>
    <Container className="py-5">
      <h3 className="mb-3">
        <strong>1. Условия</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          Заходя на сайт{" "}
          <a href="http://radar.mediacritica.md">
            radar.mediacritica.md
          </a>{" "}
          вы соглашаетесь с этими условиями обслуживания, всеми применимыми
          законами и правилами, а также соглашаетесь с тем, что несете
          ответственность за соблюдение любых применимых местных законов.Если вы
          не согласны с каким-либо из этих условий, вам запрещается
          использование или доступ к этому сайту. Материалы, содержащиеся на
          этом сайте, защищены действующим законодательством об авторских
          правах.
        </p>
      </div>
      <h3 className="mb-3">
        <strong>2. Лицензия</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          а) Разрешается временно загружать копию материалов (информации или
          программного обеспечения) на веб-сайте Media RADAR, управляемом
          Centrul pentru Jurnalism Independent (CJI), только для временного
          личного некоммерческого просмотра. Это лицензия, а не передача права
          собственности, и по этой лицензии вы не можете:
        </p>
        <ul>
          <li>модифицировать или копировать материалы;</li>
          <li>
            использовать материалы в любых коммерческих целях или для публичного
            показа (коммерческого или некоммерческого);
          </li>
          <li>
            пытаться скомпилировать или отменить любое программное обеспечение,
            содержащееся на сайте Media RADAR, управляемом IJC;
          </li>
          <li>
            удалить из-под защиты авторских прав любые другие авторские права
            или другие собственные уведомления; или передавать материалы другому
            лицу или «зеркалировать» материалы на любом другом сервере.
          </li>
        </ul>
        <p>
          б) Эта лицензия заканчивается автоматически, если вы нарушаете любое
          из этих ограничений и может быть аннулирована IJC в любое время. После
          того, как вы перестанете просматривать эти материалы или прекратите
          действие этой лицензии, вы должны уничтожить все загруженные
          материалы, находящиеся в вашем распоряжении, в электронном или
          печатном формате.
        </p>
      </div>
      <h3 className="mb-3">
        <strong>3. Отказ от ответственностиr</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          a) Материалы на веб-сайте Media RADAR, управляемом CJI,
          предоставляются «как есть». CJI не дает никаких гарантий, явных или
          подразумеваемых, и настоящим отказывается и отказывается от всех
          других гарантий, включая, помимо прочего, подразумеваемые гарантии или
          условия товарной пригодности, пригодности для определенной цели или
          несоблюдения интеллектуальной собственности или других нарушений.
        </p>
        <p>
          б) Кроме того, CJI не гарантирует и не делает никаких заявлений
          относительно точности, вероятных результатов или надежности
          использования материалов на своем веб-сайте или в связи с другими
          материалами или на сайтах, связанных с этим сайтом.
        </p>
      </div>
      <h3 className="mb-3">
        <strong>4. Ограничения</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          Ни при каких обстоятельствах CJI или его поставщики не будут нести
          ответственность за убытки (включая, помимо прочего, убытки от потери
          данных) в результате использования или невозможности использования
          материалов на веб-сайте Media RADAR, даже если CJI или представитель
          Уполномоченный CJI был уведомлен устно или письменно о возможности
          такого повреждения. Поскольку в некоторых юрисдикциях не допускаются
          ограничения подразумеваемых гарантий или ограничения ответственности
          за причинный или случайный ущерб, эти ограничения могут на вас не
          распространяться.
        </p>
      </div>
      <h3 className="mb-3">
        <strong>5. Точность материалов</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          Материалы, которые появляются на сайте Media RADAR, управляемом CJI,
          могут содержать технические, типографские или фотографические ошибки.
          CJI не гарантирует, что материалы на его сайте являются точными,
          полными, или актуальными. CJI может вносить изменения в материалы,
          содержащиеся на его веб-сайте, в любое время без предварительного
          уведомления. Тем не менее, CJI не стремится обновлять материалы.
        </p>
      </div>
      <h3 className="mb-3">
        <strong>6. Ссылки</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          CJI не проверял все сайты, связанные с его сайтом, и не несет
          ответственности за содержание такого сайта. Включение любой ссылки не
          означает одобрения сайта CJI. Использование любого такого связанного
          веб-сайта на свой страх и риск пользователя.
        </p>
      </div>
      <h3 className="mb-3">
        <strong>7. Изменения</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          CJI может пересмотреть эти условия для своего сайта в любое время без
          предварительного уведомления. Используя этот сайт, вы соглашаетесь с
          текущей версией этих условий
        </p>
      </div>
      <h3 className="mb-3">
        <strong>8. Применимое право</strong>
      </h3>
      <div className="text-block mb-4">
        <p>
          Эти условия регулируются и интерпретируются в соответствии с
          законодательством Республики Молдова, и вы безоговорочно подчиняетесь
          исключительной юрисдикции судов этого государства.
        </p>
      </div>
    </Container>
  </Row>
)

export default TermsOfServiceRu
